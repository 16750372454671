import 'App.scss';

import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from 'react-router-dom';

import GoogleRecaptchaWrapper from 'container/GoogleRecaptcha';
import MainLayoutContainer from 'container/MainLayout';
import useInitializeRender from 'hooks/useInitializeRender';
import useRoutesList from 'routes';
import { store } from 'store';

const App: React.FC = () => {
  useInitializeRender();
  const { routes } = useRoutesList();

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route
          path="/"
          element={(
            <MainLayoutContainer>
              <Outlet />
            </MainLayoutContainer>
          )}
        >
          {routes && (Object.keys(routes) as Array<keyof typeof routes>).map(
            (ele) => routes[ele].paths.map((item, index) => (
              <Route
                key={`router-${ele}-${index.toString()}`}
                path={item}
                element={routes[ele].element}
              />
            )),
          )}
        </Route>
      </Routes>
    </Suspense>
  );
};

const queryClient = new QueryClient();

const AppWrapper: React.FC = () => (
  <Provider store={store}>
    <GoogleRecaptchaWrapper>
      <QueryClientProvider client={queryClient}>
        <Router>
          <App />
        </Router>
      </QueryClientProvider>
    </GoogleRecaptchaWrapper>
  </Provider>
);

export default AppWrapper;
