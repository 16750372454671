import React from 'react';

import mapModifiers from 'utils/functions';

export const iconList = {
  search: 'search',
  email: 'email',
  emailBlack: 'emailBlack',
  location: 'location',
  phone: 'phone',
  zaloWhite: 'zaloWhite',
  youtubeWhite: 'youtubeWhite',
  facebookWhite: 'facebookWhite',
  zaloBlack: 'zaloBlack',
  youtubeBlack: 'youtubeBlack',
  facebookBlack: 'facebookBlack',
  arrowWhite: 'arrowWhite',
  arrowForward: 'arrowForward',
  arrowForwardBlack: 'arrowForwardBlack',
  arrowBackBlack: 'arrowBackBlack',
  arrowPurple: 'arrowPurple',
  loading: 'loading',
  notiValid: 'notiValid',
  notiError: 'notiError',
  cloudUpload: 'cloudUpload',
  separator: 'separator',
  clock: 'clock',
  close: 'close',
  closeWhite: 'closeWhite',
  rightQuote: 'rightQuote',
  backWhite: 'backWhite',
  swapRight: 'swapRight',
  calendar: 'calendar',
  mapPin: 'mapPin',
};

export type IconName = keyof typeof iconList;

export type IconSize = '14'| '15' | '16' | '20'| '24' | '40' | '72' | '80' | '88';
interface IconProps {
  iconName: IconName;
  size?: IconSize;
}
const Icon: React.FC<IconProps> = ({ iconName, size }) => (
  <i className={mapModifiers('a-icon', iconName, size)} />);

Icon.defaultProps = {
  size: '24',
};

export default Icon;
