import React, { useMemo } from 'react';

import useDeviceQueries from 'hooks/useDeviceQueries';
import mapModifiers from 'utils/functions';

export type SizeImageType = 'cover' | 'contain' | 'inherit' | 'initial';

interface ImageProps {
  imgSrc: string;
  srcTablet?: string;
  srcMobile?: string;
  alt: string;
  ratio: Ratio;
  size?: SizeImageType;
  loading?: 'lazy' | 'eager';
}

const Image: React.FC<ImageProps> = ({
  imgSrc,
  srcTablet,
  srcMobile,
  alt,
  ratio,
  size,
  loading = 'lazy',
}) => {
  const { isMobile, isTablet } = useDeviceQueries();
  const sourceImage = useMemo(() => {
    if (isMobile) {
      return srcMobile || imgSrc;
    }
    if (isTablet) {
      return srcTablet || imgSrc;
    }
    return imgSrc;
  }, [isMobile, isTablet, imgSrc, srcMobile, srcTablet]);

  return (
    <div className={mapModifiers('a-image', ratio, size)}>
      <img src={sourceImage} alt={alt} loading={loading} />
    </div>
  );
};

Image.defaultProps = {
  srcTablet: undefined,
  srcMobile: undefined,
  size: 'cover',
  loading: 'lazy',
};

export default Image;
