import { MenuType } from './types';

import axiosInstance from 'services/common/instance';

export const getMenuService = async (): Promise<
  APIResponse<MenuType[]>
> => {
  const response = await axiosInstance.get('menus');
  return response.data.data;
};

export const placeholder = null;
