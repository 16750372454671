import React, { lazy, useMemo } from 'react';

import { useAppSelector } from 'store/hooks';
import {
  convertHomeRoute,
  convertRoute,
  getActiveLanguages,
  convertStaticRoute,
} from 'utils/language';

const HomeNav = lazy(() => import('navigations/HomeNav'));
const PageNav = lazy(() => import('navigations/PageNav'));
const Destination = lazy(() => import('pages/Destination'));
const NewsDetail = lazy(() => import('pages/NewsDetail'));
const Product = lazy(() => import('pages/Product'));
const SearchPage = lazy(() => import('pages/SearchResult'));

const useRoutesList = () => {
  const dataSystem = useAppSelector((state) => state.system.dataSystem);

  const activeLangList = useMemo(() => getActiveLanguages(dataSystem?.locales),
    [dataSystem?.locales]);

  const routes = useMemo(
    () => {
      if (dataSystem?.locales) {
        return {
          home: {
            paths: convertHomeRoute(activeLangList),
            element: <HomeNav />,
          },
          pages: {
            paths: convertRoute(activeLangList, ':slug'),
            element: <PageNav />,
          },
          product: {
            paths: convertStaticRoute('PRODUCT', activeLangList),
            element: <Product />,
          },
          destination: {
            paths: convertStaticRoute('DESTINATION', activeLangList),
            element: <Destination />,
          },
          news: {
            paths: convertStaticRoute('NEWS', activeLangList),
            element: <NewsDetail />,
          },
          search: {
            paths: convertStaticRoute('SEARCH', activeLangList, true),
            element: <SearchPage />,
          },
        };
      }
      return undefined;
    },
    [dataSystem, activeLangList],
  );

  return { activeLangList, routes };
};

export default useRoutesList;
