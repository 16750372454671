import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const SIZE_3MB_DEFAULT = 3 * 1024 * 1024;
const phoneRegExp = /(84|0[3|5|7|8|9])+([0-9]{8})\b/;

const SUPPORTED_FORMATS = [
  'application/doc',
  'application/msword',
  'application/ms-doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
];
const useSchema = () => {
  const { t } = useTranslation();
  const schemaContactForm = yup.object({
    userName: yup.string().required(t('schemas.please_enter_your_name')),
    phone: yup.string().required(t('schemas.please_enter_the_phone_number')).matches(phoneRegExp, t('schemas.malformed')),
    email: yup.string().required(t('schemas.please_enter_your_email_address')).email(t('schemas.invalid_email')),
    group: yup.string().required(t('schemas.please_enter_the_unit_name')),
    content: yup.string().required(t('schemas.please_enter_content_address')),
    file: yup
      .mixed()
      .test('file', t('schemas.the_file_is_not_in_the_correct_format'), (file) => {
        if (!file) return true;
        return SUPPORTED_FORMATS.includes(file.type);
      })
      .test('file', t('schemas.file_must_be_less_than_3mb'), (file) => {
        if (!file) return true;
        return file.size <= SIZE_3MB_DEFAULT;
      }),
  });

  const schemaRecruitModalForm = yup.object({
    name: yup.string().required(t('schemas.please_enter_your_name')),
    phone: yup.string().required(t('schemas.please_enter_the_phone_number')).matches(phoneRegExp, t('schemas.malformed')),
    email: yup.string().required(t('schemas.please_enter_your_email_address')).email(t('schemas.invalid_email')),
    location: yup.object(),
    department: yup.object(),
    position: yup.object(),
    fileModal: yup
      .mixed()
      .test('fileModal', t('schemas.please_select_file'), (files) => {
        if (!files) return false;
        return true;
      })
      .test('fileModal', t('schemas.the_file_is_not_in_the_correct_format'), (files) => {
        if (!files) return true;
        return SUPPORTED_FORMATS.includes(files.type);
      })
      .test('fileModal', t('schemas.file_must_be_less_than_3mb'), (files) => {
        if (!files) return true;
        return files.size <= SIZE_3MB_DEFAULT;
      }),
  });

  const schemaRegisterForm = yup.object({
    name: yup.string().required(t('schemas.please_enter_your_name')),
    phone: yup.string().required(t('schemas.please_enter_the_phone_number')).matches(phoneRegExp, t('schemas.malformed')),
    email: yup.string().required(t('schemas.please_enter_your_email_address')).email(t('schemas.invalid_email')),
    location: yup.object().required(t('schemas.please_enter_location')),
    introduction: yup.string().required(t('schemas.please_enter_a_self_introduction')),
    file: yup
      .mixed()
      .test('file', t('schemas.please_select_file'), (file) => {
        if (!file) return false;
        return true;
      })
      .test('file', t('schemas.the_file_is_not_in_the_correct_format'), (file) => {
        if (!file) return true;
        return SUPPORTED_FORMATS.includes(file.type);
      })
      .test('file', t('schemas.file_must_be_less_than_3mb'), (file) => {
        if (!file) return true;
        return file.size <= SIZE_3MB_DEFAULT;
      }),
  });

  const schemaSearchForm = yup.object({
    search: yup.string(),
  });

  return {
    schemaContactForm,
    schemaRecruitModalForm,
    schemaRegisterForm,
    schemaSearchForm,
  };
};
export default useSchema;

export const remove = '';
