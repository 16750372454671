import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getStaticErrorPagesService } from '../services/navigations/index';

import { StaticErrorData } from 'services/navigations/types';

interface StaticPageState {
  errorPages: StaticErrorData[];
}

const initialState: StaticPageState = {
  errorPages: [],
};

export const getStaticErrorPageAction = createAsyncThunk<
  StaticErrorData[]>(
    'staticPageReducer/getStaticErrorPageAction',
    async (_, { rejectWithValue }): Promise<StaticErrorData[] | any> => {
      try {
        const res = await getStaticErrorPagesService();
        return res.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    },
  );

export const staticPageSlice = createSlice({
  name: 'staticPageReducer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getStaticErrorPageAction.fulfilled, ($state, action) => {
      $state.errorPages = action.payload;
    });
  },
});

export default staticPageSlice.reducer;
