import { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';

import useLanguage from './useLanguage';

import i18n, { changeStoreLanguage, detectLanguage } from 'i18n';
import { LanguageKey } from 'services/system/types';
import { useAppDispatch, useAppSelector } from 'store';
import { getHeaderMenuAsync } from 'store/menu';
import { getSystemAsync, setLanguage } from 'store/system';
import { checkActiveLang } from 'utils/language';

const useInitializeRender = () => {
  const location = useLocation();

  const dispatch = useAppDispatch();

  const system = useAppSelector((state) => state.system.dataSystem);
  const isChangeLanguage = useAppSelector((state) => state.system.isChangeLanguage);

  const [initializedGA, setInitializedGA] = useState(false);

  const { handleShowModal } = useLanguage();

  useEffect(() => {
    document.body.classList.remove('overflow-body');
    document.documentElement.classList.remove('overflow-body');
    window.scrollTo(0, 0);
    const checkLanguage = changeStoreLanguage(location.pathname);
    if (checkLanguage.change) {
      i18n.changeLanguage(checkLanguage.language);
      dispatch(setLanguage(checkLanguage.language));
    }
  }, [location.pathname, dispatch]);

  //* Init - recall when change language
  useEffect(() => {
    dispatch(getSystemAsync()).unwrap().then((res) => {
      const languageKey = detectLanguage() as LanguageKey;
      if (checkActiveLang(languageKey, res.locales)) {
        dispatch(getHeaderMenuAsync());
      } else {
        const message = res?.locales
          ? res.locales[languageKey].message
          : '';
        handleShowModal(languageKey, message);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, handleShowModal]);

  useEffect(() => {
    if (isChangeLanguage) {
      dispatch(getSystemAsync());
      dispatch(getHeaderMenuAsync());
    }
  }, [dispatch, isChangeLanguage]);
  //* End Init - recall when change language

  //* GTM-GA
  useEffect(() => {
    if (system?.gaId) {
      ReactGA.initialize(system.gaId);
      setInitializedGA(true);
    }
    if (system?.gtmId) {
      TagManager.initialize({
        gtmId: system.gtmId,
      });
    }
  }, [system]);

  useEffect(() => {
    if (initializedGA && location) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initializedGA, location]);
  //* End GTM-GA
};

export default useInitializeRender;
