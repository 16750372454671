import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getMenuService } from 'services/menus';
import { MenuItem, MenuType } from 'services/menus/types';
import groupMenus, { typePrefixGroupMenu } from 'utils/groupMenus';

interface MenuState {
  header?: MenuItem[];
  footer?: MenuItem[];
}

const initialState: MenuState = {
  header: undefined,
  footer: undefined,
};

const menuFilter = (data: MenuType[], type: 'footer' | 'menu') => {
  const menuData = data.find((item) => item.code === type);
  return groupMenus(typePrefixGroupMenu(menuData?.items));
};

export const getHeaderMenuAsync = createAsyncThunk(
  'menuReducer/menuHeader',
  async (_, { rejectWithValue }): Promise<MenuType[] | any> => {
    try {
      const response = await getMenuService();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const menuSlice = createSlice({
  name: 'menuReducer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getHeaderMenuAsync.fulfilled, ($state, action) => {
      $state.header = menuFilter(action.payload, 'menu');
      $state.footer = menuFilter(action.payload, 'footer');
    });
  },
});

export default menuSlice.reducer;
