import React, { useEffect, useRef, useState } from 'react';

import useClickOutside from 'hooks/useClickOutside';
import mapModifiers from 'utils/functions';

type Modifiers = 'outlined' | 'radius' | 'triangleGhostWhite';

export interface OptionType {
  id?: string;
  label: string;
  value: string;
}

interface PulldownProps {
  modifier?: Modifiers;
  id?: string;
  name?: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  value?: OptionType;
  options: OptionType[];
  isSearch?: boolean;
  error?: string;
  disabled?: boolean;
  handleSelect?: (option: OptionType) => void;
}

const renderLabel = (info: Pick<PulldownProps, 'label' | 'id' | 'required'>) => {
  const { label, id, required } = info;

  if (!label) return null;

  return (
    <label className="m-pulldown_label" htmlFor={id}>
      {label}
      {required && <span className="m-pulldown_label-required">*</span>}
    </label>
  );
};

const Pulldown: React.FC<PulldownProps> = ({
  modifier,
  id,
  name,
  label,
  required,
  placeholder,
  value,
  options,
  isSearch,
  error,
  disabled,
  handleSelect,
}) => {
  const pulldownRef = useRef<HTMLDivElement>(null);
  const [optionData, setOptionData] = useState<OptionType[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [txtSearch, setTxtSearch] = useState('');
  const toggling = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  useClickOutside(pulldownRef, () => {
    if (isOpen) setIsOpen(false);
  });

  useEffect(() => {
    if (txtSearch) {
      setOptionData(
        options.filter(
          (option) => option?.label.toLocaleLowerCase().includes(txtSearch.toLocaleLowerCase()),
        ),
      );
    } else {
      setOptionData(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [txtSearch]);

  useEffect(() => {
    setOptionData(options);
  }, [options]);

  return (
    <div className={`${mapModifiers('m-pulldown', modifier, error && 'error')}`} ref={pulldownRef}>
      {renderLabel({
        label,
        id,
        required,
      })}
      <div className="m-pulldown_header" onClick={toggling}>
        {!txtSearch && (
          <div className={`m-pulldown_header_content${value ? '' : ' m-pulldown_placeholder'}`}>
            <span>{value ? value.label : placeholder}</span>
          </div>
        )}
        {isSearch && (
          <input
            name={name}
            className="m-pulldown_search"
            value={txtSearch}
            onChange={(e) => setTxtSearch(e.currentTarget.value)}
          />
        )}
        <span className={isOpen ? 'm-pulldown_arrow opened' : 'm-pulldown_arrow'} />
      </div>
      {isOpen && (
        <div className="m-pulldown_wrapper">
          <ul className="m-pulldown_list">
            {optionData.length ? (
              optionData.map((option) => (
                <li
                  key={option.id}
                  className={mapModifiers(
                    'm-pulldown_item',
                    value?.label === option.label && 'active',
                  )}
                  onClick={() => {
                    if (handleSelect) {
                      handleSelect(option);
                      setTxtSearch('');
                      setIsOpen(false);
                    }
                  }}
                >
                  <span>{option.label}</span>
                </li>
              ))
            ) : (
              <li className="m-pulldown_item none">No Option</li>
            )}
          </ul>
        </div>
      )}
      {error && <span className="m-pulldown_error">{error}</span>}
    </div>
  );
};

Pulldown.defaultProps = {
  modifier: undefined,
  id: undefined,
  isSearch: undefined,
  placeholder: undefined,
  name: undefined,
  label: undefined,
  required: undefined,
  value: undefined,
  handleSelect: undefined,
  error: undefined,
  disabled: undefined,
};

export default Pulldown;
