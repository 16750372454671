import React from 'react';
import { NavLink } from 'react-router-dom';

import { checkExternalUrl } from 'utils/functions';

export interface LinkProps {
  href?: string;
  customClassName?: string;
  useExternal?: boolean;
  target?: string;
  title?: string;
  activeClassName?: string;
  handleClick?: () => void;
}

const Link: React.FC<LinkProps> = ({
  href,
  useExternal,
  title,
  customClassName = '',
  target = '_self',
  activeClassName = '',
  children,
  handleClick,
}) => {
  if (useExternal || checkExternalUrl(href)) {
    return (
      <a
        className={`a-link ${customClassName} ${activeClassName}`}
        target={target}
        href={href}
        rel="noreferrer"
        title={title}
        onClick={handleClick}
      >
        {children}
      </a>
    );
  }
  return (
    <NavLink
      title={title}
      to={href || '#'}
      className={`a-link ${customClassName} ${activeClassName}`}
      onClick={handleClick}
      target={target}
    >
      {children}
    </NavLink>
  );
};

Link.defaultProps = {
  href: undefined,
  customClassName: undefined,
  useExternal: undefined,
  target: undefined,
  title: undefined,
  activeClassName: undefined,
  handleClick: undefined,
};

export default Link;
