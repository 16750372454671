export const BASE_URL = process.env.REACT_APP_BASE_URL;

function mapModifiers(
  baseClassName: string,
  ...modifiers: (string | string[] | false | undefined)[]
): string {
  return modifiers
    .reduce<string[]>(
      (acc, m) => (!m ? acc : [...acc, ...(typeof m === 'string' ? [m] : m)]),
      [],
    )
    .map((m) => `-${m}`)
    .reduce<string>(
      (classNames, suffix) => `${classNames} ${baseClassName}${suffix}`,
      baseClassName,
    );
}

export default mapModifiers;

/*!
 * Scroll down to next block element
 */
export function scrollDownNextSection(ref: React.RefObject<HTMLDivElement>) {
  if (ref && ref.current) {
    window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop - 68 }); // Minus header height
  }
}

/*!
 * getMousePosition(event) - cross browser normalizing of:
 * clientX, clientY, screenX, screenY, offsetX, offsetY, pageX, pageY
 * HTMLElement
 */
export function getMousePosition(
  evt:
    | React.MouseEvent<SVGPathElement, MouseEvent>
    | React.MouseEvent<SVGRectElement, MouseEvent>,
  item: HTMLDivElement,
) {
  let { pageX } = evt;
  let { pageY } = evt;
  if (pageX === undefined) {
    pageX = evt.clientX
      + document.body.scrollLeft
      + document.documentElement.scrollLeft;
    pageY = evt.clientY
      + document.body.scrollTop
      + document.documentElement.scrollTop;
  }

  const rect = item.getBoundingClientRect();
  const offsetX = evt.clientX - rect.left;
  const offsetY = evt.clientY - rect.top;

  return {
    client: { x: evt.clientX, y: evt.clientY }, // relative to the viewport
    screen: { x: evt.screenX, y: evt.screenY }, // relative to the physical screen
    offset: { x: offsetX, y: offsetY }, // relative to the event target
    page: { x: pageX, y: pageY }, // relative to the html document
  };
}

export function getDimensions(ele: HTMLDivElement) {
  const { height } = ele.getBoundingClientRect();
  const { offsetTop } = ele;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
}

export function scrollStop(callback: (value: any) => void, time = 2000) {
  // Make sure a valid callback was provided
  if (!callback || typeof callback !== 'function') return;

  // Setup scrolling variable
  let isScrolling: any;

  // Listen for scroll events
  window.addEventListener(
    'scroll',
    () => {
      // Clear our timeout throughout the scroll
      window.clearTimeout(isScrolling);

      // Set a timeout to run after scrolling ends
      isScrolling = setTimeout(callback, time);
    },
    false,
  );
}

export const dataURLtoFile = (dataUrl: string, filename: string) => {
  const arr = dataUrl.split(',');
  const mine = 'image/jpeg';
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mine });
};

export const checkExternalUrl = (str?: string) => {
  if (!str) return false;
  const tareaRegex = /^(http|https)/;
  return tareaRegex.test(String(str).toLowerCase());
};

export const classAnimated = (animate: boolean, extendClassName: string, type?: string) => {
  if (animate) return `${extendClassName} animate animate-${type || ''}`;
  return `${extendClassName} preanimate`;
};

export const handleScrollCenter = (ref: React.RefObject<HTMLDivElement | null>,
  classNameEleActive: string) => {
  const eleScroll = ref.current;
  const eleActive = document.querySelector(classNameEleActive);
  if (!eleActive || !eleScroll) return;
  // get width element scroll
  const widthEleScroll = eleScroll.getBoundingClientRect().width;
  // get distance element scroll compared to y window
  const xEleScroll = eleScroll.getBoundingClientRect().x;
  // get width element active
  const widthEleActive = eleActive.getBoundingClientRect().width;
  // get distance element active compared to y window
  const xEleActive = eleActive.getBoundingClientRect().x;
  // get position sroll bar
  const positionScroll = eleScroll.scrollLeft;
  const scrollX = xEleActive
    - xEleScroll
    + widthEleActive / 2
    + positionScroll
    - widthEleScroll / 2;
  eleScroll.scroll({
    left: scrollX,
    behavior: 'smooth',
  });
};
export function convertLocalToUTCDate(date: Date) {
  if (!date) {
    return date;
  }
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
  );
}

export function range(start: number, end: number) {
  return new Array(end - start + 1).fill(undefined).map((_, i) => ({
    label: String(end - i),
    value: String(end - i),
  }));
}

export const years = range(1990, new Date().getFullYear());

export function getMonth(date: Date) {
  return new Date(date).getMonth();
}

export const months = [
  { label: 'January', value: 'January' },
  { label: 'February', value: 'February' },
  { label: 'March', value: 'March' },
  { label: 'April', value: 'April' },
  { label: 'May', value: 'May' },
  { label: 'June', value: 'June' },
  { label: 'July', value: 'July' },
  { label: 'August', value: 'August' },
  { label: 'September', value: 'September' },
  { label: 'October', value: 'October' },
  { label: 'November', value: 'November' },
  { label: 'December', value: 'December' },
];

export function getBlockData<T>(
  _code: string,
  listBlock?: BlockComponents<T>[],
): T | undefined {
  if (!listBlock) return undefined;
  return listBlock.find((item) => item.code === _code)?.blocks;
}

export function getImageURL(imgUrl?: string) {
  if (!imgUrl) return '';
  return (BASE_URL ?? '') + imgUrl;
}

export function getBannerData(
  _code: string,
  listBlock: BannersData[],
): BannerData | undefined {
  if (!listBlock) return undefined;
  const findIndex = listBlock.findIndex((item) => item.type === _code);
  if (findIndex < 0) {
    return undefined;
  }
  return listBlock[findIndex].data;
}

const formatKey = (key:string) => key.replace('_', '-').toLowerCase();

export function formatKebabParams<T>(params?:T) {
  if (!params) return undefined;
  return Object.keys(params).reduce((prev, cur) => {
    const obj :any = {};
    const key = formatKey(cur);
    obj[key] = params[cur as keyof T];
    return ({ ...prev, ...obj });
  },
  {});
}

export const formatDateDDMMYYYY = (date?: string) => {
  if (!date) return '';
  const dateFormat = new Date(date);
  let day: string | number = dateFormat.getDate();
  let month: string | number = dateFormat.getMonth() + 1;
  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }
  return `${day}/${month}/${dateFormat.getFullYear()}`;
};

export const formatDateYYYYMMDD = (date?: Date) => {
  if (!date) return '';
  const dateFormat = new Date(date);
  let day: string | number = dateFormat.getDate();
  let month: string | number = dateFormat.getMonth() + 1;
  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }
  return `${dateFormat.getFullYear()}-${month}-${day}`;
};

export const convertTime = (params: string) => {
  if (!params) return '';

  const date = new Date(params);
  let month = `${date.getMonth() + 1}`;
  let day = `${date.getDate()}`;
  const year = date.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [day, month, year].join('/');
};

export const checkMetaData = (metaData?: string) => {
  if (!metaData) return '';
  return metaData;
};

export const formatPhone = (key:string) => key.replace(/[- )(]/g, '');
