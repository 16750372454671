/* eslint-disable react/button-has-type */
import React from 'react';

import Icon from 'components/atoms/Icon';
import mapModifiers from 'utils/functions';

export type VariantButton = 'primary' | 'secondary' | 'outline' | 'orange';
export type StyleButton = 'md' | 'lg' | 'inline';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: VariantButton;
  modifiers?: (StyleButton)[];
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  variant,
  modifiers,
  name,
  id,
  className,
  disabled,
  type = 'button',
  loading,
  onClick,
}) => (
  <button
    id={id}
    name={name}
    type={type}
    disabled={disabled}
    onClick={onClick}
    className={mapModifiers('a-button', variant, modifiers, className, loading && 'loading')}
  >
    {loading ? <Icon iconName="loading" /> : children}
  </button>
);

Button.defaultProps = {
  variant: 'primary',
  modifiers: ['md'],
  loading: false,
};

export default Button;
