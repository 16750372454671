import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { useAppSelector } from 'store/hooks';

const GoogleRecaptchaWrapper: React.FC = ({ children }) => {
  const googleRecaptchaSiteKey = useAppSelector(
    (state) => state.system.dataSystem?.googleRecaptchaSiteKey,
  );

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={googleRecaptchaSiteKey}
      scriptProps={{
        appendTo: 'head',
        defer: true,
        async: true,
      }}
    >
      {children}
    </GoogleReCaptchaProvider>
  );
};

export default GoogleRecaptchaWrapper;
