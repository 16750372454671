import React from 'react';

import Text from 'components/atoms/Text';
import mapModifiers from 'utils/functions';

interface NotifyProps {
  modifiers?: 'warning' | 'success';
  message: string;
}

const Notify: React.FC<NotifyProps> = ({
  message, modifiers,
}) => (
  <div className={mapModifiers('m-notify', modifiers, message.length > 0 && 'show')}>
    <div className="m-notify_layer" />
    <div className="m-notify_body">
      <div className="m-notify_icon" />
      {message && (
        <div className="m-notify_message">
          <Text modifiers={['700', 'white', 'center']}>{message}</Text>
        </div>
      )}
    </div>
  </div>
);

Notify.defaultProps = {
  modifiers: 'success',
};

export default Notify;
