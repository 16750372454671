import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { createSelectorHook, useDispatch } from 'react-redux';

import exampleReducer from './example';
import menuReducer from './menu';
import staticPageReducer from './staticPage';
import systemReducer from './system';

export const store = configureStore({
  devTools: process.env.NODE_ENV === 'development',
  reducer: {
    example: exampleReducer,
    system: systemReducer,
    staticPage: staticPageReducer,
    menu: menuReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export const useAppSelector = createSelectorHook<RootState>();
export const useAppDispatch = () => useDispatch<AppDispatch>();
