import { yupResolver } from '@hookform/resolvers/yup';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Notify from 'components/molecules/Notify';
import MainLayout from 'components/templates/MainLayout';
import { SearchForm } from 'components/templates/SearchResult';
import useLanguage from 'hooks/useLanguage';
import { useAppSelector } from 'store/hooks';
import { getImageURL } from 'utils/functions';
import { getPrefixStaticSlug } from 'utils/language';
import useSchema from 'utils/schemas';

const MainLayoutContainer: React.FC = ({ children }) => {
  const navigate = useNavigate();

  const { system: { messageNotify } } = useAppSelector((state) => state);
  const { header, footer } = useAppSelector((state) => state.menu);
  const { dataSystem } = useAppSelector((state) => state.system);
  const language = useAppSelector((state) => state.system.language);
  const { schemaSearchForm } = useSchema();
  const {
    lang,
    languageData,
    handleChangeLang,
  } = useLanguage();

  const social = useMemo(() => {
    if (dataSystem && dataSystem.socials.length > 0) {
      return dataSystem.socials.map((item) => ({
        icon: getImageURL(item.icon),
        href: item.link,
      }));
    }
    return [];
  }, [dataSystem]);

  const relate = useMemo(() => {
    if (footer && footer.length > 0) {
      return {
        text: footer[0].title,
        list: footer[0].subMenu ? footer[0].subMenu.map((e) => ({
          text: e.title,
          href: e.reference?.slug || e.link || '',
        })) : [],
      };
    }
    return undefined;
  }, [footer]);

  const aboutData = useMemo(() => {
    if (footer && footer.length > 1) {
      const list = [];
      list.push({
        text: footer[1].title,
        href: footer[1].reference?.slug || footer[1].link || '',
      });
      if (footer[1].subMenu) {
        footer[1].subMenu.forEach((item) => {
          list.push({
            text: item.title,
            href: item.reference?.slug || item.link || '',
          });
        });
      }
      return list;
    }
    return [];
  }, [footer]);

  //* Search
  const searchMethod = useForm<SearchForm>({
    resolver: yupResolver(schemaSearchForm),
    defaultValues: {
      search: '',
    },
  });

  const handleSearch = (data: SearchForm) => {
    if (data.search) {
      navigate(`/${getPrefixStaticSlug('SEARCH', language)}?keyword=${data.search}`);
      searchMethod.reset();
    }
  };
  //* End search

  if (!dataSystem) {
    return null;
  }

  return (
    <MainLayout
      header={{
        srcLogo: getImageURL(dataSystem.logo),
        contactInfo: {
          email: dataSystem.contactEmail,
          phone: dataSystem.contactPhoneNumber,
        },
        menu: header || [],
        selectedLang: lang,
        linkBuyTicket: dataSystem.linkBuyTicket,
        languageData,
        searchMethod,
        handleSearch,
        handleChangeLang,
      }}
      footer={{
        srcBg: getImageURL(dataSystem.footImage),
        srcLogo: getImageURL(dataSystem.footerLogo),
        info: {
          text: dataSystem.footer.description || '',
          email: {
            address: dataSystem.footer.emailText || '',
            text: dataSystem.footer.emailText || '',
          },
          phone: {
            number: dataSystem.footer.hotlineText || '',
            text: dataSystem.footer.hotlineText || '',
          },
          social,
        },
        relate,
        about: {
          list: aboutData,
        },
        term: {
          policy: {
            text: dataSystem.footer.privacyPolicy.text || '',
            href: dataSystem.footer.privacyPolicy.url || '/',
          },
          security: {
            text: dataSystem.footer.termsOfUse.text || '',
            href: dataSystem.footer.termsOfUse.url || '',
          },
          copyRight: dataSystem.footer.copyright || '',
        },
      }}
    >
      {children}
      <Notify message={messageNotify.message} modifiers={messageNotify.type} />
    </MainLayout>
  );
};
export default MainLayoutContainer;
