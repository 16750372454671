export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const LOCAL_STORAGE = {
  LANGUAGE: 'NOVA_DREAM_Language',
};

export const CONSTANT_LANGUAGE_LIST = ['vi', 'en'];

export const DEFAULT_QUERY_OPTION = {
  retry: 0,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
};

export const CONSTANT_PREFIX = {
  PRODUCT: 'san-pham',
  DESTINATION: 'diem-den',
  NEWS: 'tin-tuc',
  SEARCH: 'tim-kiem',
};

export const CONSTANT_PREFIX_EN = {
  PRODUCT: 'product',
  DESTINATION: 'destination',
  NEWS: 'news',
  SEARCH: 'search',
};

export type ConstantRoutesCodes = keyof typeof CONSTANT_PREFIX;
