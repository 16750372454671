import React, { lazy } from 'react';
import { useLocation } from 'react-router-dom';

import { FooterProps } from 'components/organisms/Footer';
import Header, { HeaderProps } from 'components/organisms/Header';
import mapModifiers from 'utils/functions';

const Footer = lazy(() => import('components/organisms/Footer'));

interface MainLayoutProps {
  footer: FooterProps;
  header: HeaderProps;
}

const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  footer,
  header,
}) => {
  const location = useLocation();
  return (
    <>
      <Header {...header} />
      <main className={mapModifiers('t-mainLayout', location.pathname === '/' && 'home')}>
        {children}
      </main>
      <Footer {...footer} />
    </>
  );
};

MainLayout.defaultProps = {};

export default MainLayout;
