import { StaticErrorData } from './types';

import axiosInstance from 'services/common/instance';

export const getStaticHomeService = async (): Promise<BasePageData<any>> => {
  const response = await axiosInstance.get('pages/static/home-page');
  return response.data.data;
};

export const getPageService = async (
  slug: string,
): Promise<BasePageData<any>> => {
  const response = await axiosInstance.get(`pages/${slug}`);
  return response.data.data;
};

export const getPreviewService = async <T>(token: string): Promise<T> => {
  const response = await axiosInstance.get(`preview/${token}`);
  return response.data.data;
};

export const getStaticErrorPagesService = async (): Promise<APIResponse<StaticErrorData[]>> => {
  const res = await axiosInstance.get('pages/static/errors');
  return res.data;
};
