import i18n from 'i18n';
import { MenuItem, MenuItemDataTypes } from 'services/menus/types';
import { getPrefixStaticSlug } from 'utils/language';

// Recursive Menu
const groupMenus = (menus?: MenuItem[]) => {
  if (!menus) return undefined;
  const recursiveMenus = (
    menuList: MenuItem[],
    parentId: number,
  ): MenuItem[] => {
    const menusGrouped: MenuItem[] = [];
    menuList.forEach((menu) => {
      if (menu.parentId === parentId) {
        const subMenus = recursiveMenus(menuList, menu.id);
        menusGrouped.push(
          subMenus.length > 0
            ? {
              ...menu,
              subMenu: subMenus,
            }
            : {
              ...menu,
            },
        );
      }
    });
    return menusGrouped;
  };
  if (menus.length > 0) {
    const firstLevelParentId = menus.find((menu) => menu.depth === 1)!.parentId;
    return recursiveMenus(menus, firstLevelParentId);
  }
  return [];
};

export const detectTypePrefix = (type: string, lang: string) => {
  switch (type) {
    // Destination
    case 'OneContent\\Destination\\Models\\Destination':
      return getPrefixStaticSlug('DESTINATION', lang);
    // Product
    case 'OneContent\\Product\\Models\\Product':
      return getPrefixStaticSlug('PRODUCT', lang);
    default:
      return '';
  }
};

export const detectTypeHeaderActive = (pathname: string, type?: string) => {
  if (!type) return false;
  switch (type) {
    // Destination
    case 'Destination':
      return pathname.split('/')[i18n.language === 'vi' ? 1 : 2].includes(getPrefixStaticSlug('DESTINATION', i18n.language));
    // Product
    case 'Product':
      return pathname.split('/')[i18n.language === 'vi' ? 1 : 2].includes(getPrefixStaticSlug('PRODUCT', i18n.language));
    // News
    case 'News':
      return pathname.split('/')[i18n.language === 'vi' ? 1 : 2].includes(getPrefixStaticSlug('NEWS', i18n.language));
    default:
      return false;
  }
};

export const getLanguagePrefix = (lang: string) => {
  if (lang && lang !== 'vi') return `${lang}/`;
  return '';
};

export const typePrefixGroupMenu = (menus?: MenuItemDataTypes[]) => {
  if (!menus) {
    return [];
  }

  return menus.map((ele) => {
    const prefix = detectTypePrefix(ele.type, i18n.language);

    if (prefix) {
      return {
        ...ele,
        reference: ele.reference && ele.reference.slug
          ? {
            slug: `${prefix}/${ele.reference?.slug === '/' ? '' : ele.reference?.slug}`,
          }
          : undefined,
      };
    }
    return {
      ...ele,
      reference: ele.reference && ele.reference.slug
        ? {
          slug: `${ele.reference.slug === '/' ? `${getLanguagePrefix(i18n.language) || '/'}` : `${getLanguagePrefix(i18n.language)}${ele.reference.slug}`}`,
        }
        : undefined,
    };
  }) as MenuItemDataTypes[];
};

export const checkLinkHeader = (ele: MenuItem) => {
  if (ele.link) {
    return ele.link;
  }
  if (ele.reference?.slug) {
    return ele.reference.slug === '/' ? ele.reference?.slug : `/${ele.reference?.slug}`;
  }
  return '#';
};

export default groupMenus;
