/* eslint-disable react/require-default-props */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Image from 'components/atoms/Image';
import Input from 'components/atoms/Input';
import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';
import Pulldown, { OptionType } from 'components/molecules/Pulldown';
import Container from 'components/organisms/Container';
import { SearchForm } from 'components/templates/SearchResult';
import useDetectHeader from 'hooks/useDetectHeader';
import { MenuItem as MenuItemTypes } from 'services/menus/types';
import mapModifiers, { checkExternalUrl, formatPhone } from 'utils/functions';
import { detectTypeHeaderActive, checkLinkHeader } from 'utils/groupMenus';
import { getHomeLangURL } from 'utils/language';

interface HamburgerProps {
  active?: boolean;
  handleClick?: () => void;
}

interface HeaderMainProps {
  reactNodeHamburger: React.ReactNode;
  reactNodeMenu: React.ReactNode;
  reactNodeLogo: React.ReactNode;
  reactNodeButton: React.ReactNode;
}

interface InfoProps {
  email?: string;
  phone?: string;
}

interface HeaderSubProps {
  reactNodeHamburger: React.ReactNode;
  reactNodeInput: React.ReactNode;
  reactNodeLanguage: React.ReactNode;
  reactNodeMenu: React.ReactNode;
  reactNodeInfo: React.ReactNode;
}

const Hamburger: React.FC<HamburgerProps> = ({ active, handleClick }) => (
  <div className={mapModifiers('hamburger', active && 'active')} onClick={handleClick}>
    <span />
    <span />
    <span />
  </div>
);
interface InternalMenuItemTypes {
  isChild?: boolean;
  idExpand?: Array<number | undefined>;
  active?: boolean;
  pageSlug?: string;
  pathname: string;
  handleClickExpand?: (item: MenuItemTypes) => void;
  handleCloseMenu?: () => void;
}

export interface MenuItemProps extends MenuItemTypes, InternalMenuItemTypes { }

const recursiveMenuActive = (item: MenuItemTypes, pathname: string, cb: () => void) => {
  if ((item.reference || item.link) && !item.cssClass) {
    if (item?.reference?.slug === '/' && pathname === '/') {
      cb();
    } else if ((`/${item?.reference?.slug}` || item.link) === pathname) {
      cb();
    }
  } else if (detectTypeHeaderActive(pathname, item.cssClass)) {
    cb();
  }
};

export const checkActiveMenu = (item: MenuItemTypes, pathname: string) => {
  let flag = false;
  recursiveMenuActive(item, pathname, () => {
    flag = true;
  });
  return flag;
};

const MenuItem: React.FC<MenuItemProps> = ({
  isChild,
  idExpand,
  active,
  pageSlug,
  pathname,
  handleClickExpand,
  handleCloseMenu,
  ...props
}) => (
  <li
    className={mapModifiers(
      'menu-item',
      isChild && 'isChild',
      !isChild && checkActiveMenu(props, pathname) && 'active',
      props.subMenu?.length ? 'hasChild' : undefined,
      idExpand && idExpand.includes(props.id) && 'expand',
    )}
  >
    {!props.subMenu?.length && (
      <Link
        useExternal={props.type === 'custom_link' && checkExternalUrl(props.link)}
        handleClick={handleCloseMenu}
        href={checkLinkHeader(props)}
        target={props.target}
        customClassName="menu-item_link"
      >
        {props.title}
      </Link>
    )}
    {props.subMenu?.length && (
      <span
        onClick={(e) => {
          e.stopPropagation();
          if (handleClickExpand) handleClickExpand(props);
        }}
        className="menu-item_link"
      >
        {props.reference?.slug || props.link ? (
          <Link
            useExternal={props.type === 'custom_link' && checkExternalUrl(props.link)}
            handleClick={handleCloseMenu}
            href={checkLinkHeader(props)}
            target={props.target}
            customClassName="menu-item_title"
          >
            {props.title}
          </Link>
        ) : (
          props.title
        )}
      </span>
    )}
    {!isChild && <span className="menu-item_line" />}
    {props.subMenu?.length && (
      <>
        <div className="menu-item_wrap">
          <ul className={mapModifiers('menu-item_list', isChild && 'deep')}>
            {props.subMenu.map((x, i) => (
              <MenuItem
                {...x}
                idExpand={idExpand}
                handleClickExpand={handleClickExpand}
                handleCloseMenu={handleCloseMenu}
                key={`menu-item-${x.id}-${i.toString()}`}
                isChild
                pageSlug={pageSlug}
                pathname={pathname}
              />
            ))}
          </ul>
        </div>
      </>
    )}
  </li>
);

export interface HeaderMenuProps extends InternalMenuItemTypes {
  menu: MenuItemTypes[];
  pageSlug?: string;
  pathname: string;
  isHideBtn?: boolean;
}

const HeaderMenu: React.FC<HeaderMenuProps> = ({
  isHideBtn,
  menu,
  pageSlug,
  pathname,
  ...props
}) => (
  <ul className={mapModifiers('menu', isHideBtn && 'isHideBtn')}>
    {menu.map((item, index) => (
      <MenuItem
        pageSlug={pageSlug}
        pathname={pathname}
        key={`menu-${index.toString()}`}
        {...item}
        {...props}
      />
    ))}
  </ul>
);

const HeaderMain: React.FC<HeaderMainProps> = ({
  reactNodeLogo,
  reactNodeButton,
  reactNodeHamburger,
  reactNodeMenu,
}) => (
  <div className="o-header_main">
    <div className="o-header_main_hamburger">{reactNodeHamburger}</div>
    <div className="o-header_main_left">
      <div className="o-header_main_logo">{reactNodeLogo}</div>
      <div className="o-header_main_menu">{reactNodeMenu}</div>
    </div>
    {reactNodeButton && (
      <div className="o-header_main_right">
        <div className="o-header_main_btn-buy">{reactNodeButton}</div>
      </div>
    )}
  </div>
);

const Info: React.FC<InfoProps> = ({ email, phone }) => (
  <div className="o-header_info">
    <div className="o-header_info_mail">
      <Link useExternal href="mailto:mkt@novadreams.com.vn">
        <Icon iconName="email" size="24" />
        <Text type="span" modifiers={['taupeGray']}>
          {email}
        </Text>
      </Link>
    </div>
    <div className="o-header_separate" />
    {phone && (
      <div className="o-header_info_phone">
        <Link useExternal href={`tel:${formatPhone(phone)}`}>
          <Icon iconName="phone" size="24" />
          <Text type="span" modifiers={['taupeGray']}>
            {phone}
          </Text>
        </Link>
      </div>
    )}
  </div>
);

const HeaderSub: React.FC<HeaderSubProps> = ({
  reactNodeHamburger,
  reactNodeInput,
  reactNodeLanguage,
  reactNodeMenu,
  reactNodeInfo,
}) => (
  <div className="o-header_sub">
    <div className="o-header_sub_left">{reactNodeInfo}</div>
    <div className="o-header_sub_menu">{reactNodeMenu}</div>
    <div className="o-header_sub_right">
      <div className="o-header_sub_input">{reactNodeInput}</div>
      <div className="o-header_separate" />
      <div className="o-header_sub_util">
        <div className="o-header_sub_hamburger">{reactNodeHamburger}</div>
        <div className="o-header_sub_language">{reactNodeLanguage}</div>
      </div>
    </div>
  </div>
);

export interface HeaderProps {
  srcLogo: string;
  menu: MenuItemTypes[];
  selectedLang: OptionType;
  languageData: OptionType[];
  searchMethod: UseFormReturn<SearchForm>;
  contactInfo: InfoProps;
  linkBuyTicket?: ActionTypes;
  handleSearch: (data: SearchForm) => void;
  handleChangeLang?: (option: OptionType) => void;
}
const Header: React.FC<HeaderProps> = ({
  srcLogo,
  menu,
  selectedLang,
  languageData,
  searchMethod,
  contactInfo,
  linkBuyTicket,
  handleSearch,
  handleChangeLang,
}) => {
  const { t } = useTranslation();

  const { slug: pageSlug } = useParams();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [idExpand, setIdExpand] = useState<Record<'parent' | 'child', number | undefined>>();
  const [isScroll, setIsScroll] = useState(false);

  const handleCloseMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleClickExpand = useCallback(
    (item: MenuItemTypes) => {
      if (window.innerWidth > 991) return;
      if (item.id === idExpand?.child) {
        setIdExpand({ child: undefined, parent: idExpand?.parent });
        return;
      }
      if (item.id === idExpand?.parent) {
        setIdExpand(undefined);
        return;
      }
      setIdExpand({ child: item.id, parent: item.parentId });
    },
    [idExpand?.child, idExpand?.parent],
  );

  const onSubmit = (val: SearchForm) => {
    handleSearch(val);
    handleCloseMenu();
  };

  useEffect(() => {
    setIdExpand(undefined);
  }, [isOpen]);

  useEffect(() => {
    let prevScrollpos = window.pageYOffset;
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      if (window.pageYOffset < 70 || prevScrollpos > currentScrollPos) {
        setIsScroll(false);
      } else {
        setIsScroll(true);
      }
      prevScrollpos = currentScrollPos;
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScroll]);

  useDetectHeader(isOpen, handleCloseMenu);

  const MenuNode = useMemo(
    () => (
      <HeaderMenu
        menu={menu}
        idExpand={[idExpand?.child, idExpand?.parent]}
        handleCloseMenu={handleCloseMenu}
        handleClickExpand={handleClickExpand}
        pageSlug={pageSlug}
        pathname={pathname}
        isHideBtn={!linkBuyTicket?.text}
      />
    ),
    [
      menu,
      idExpand?.child,
      idExpand?.parent,
      handleCloseMenu,
      handleClickExpand,
      pageSlug,
      pathname,
      linkBuyTicket,
    ],
  );

  return (
    <header className={mapModifiers('o-header', isOpen && 'open', isScroll && 'scroll')}>
      <div className="o-header_wrapMain">
        <Container>
          <HeaderMain
            reactNodeLogo={(
              <Link href={getHomeLangURL(selectedLang?.value)}>
                <Image imgSrc={srcLogo} alt="logo" ratio="105x55" size="contain" />
              </Link>
            )}
            reactNodeButton={linkBuyTicket?.text ? (
              <Link
                useExternal={checkExternalUrl(linkBuyTicket?.url)}
                href={linkBuyTicket?.url}
                target={linkBuyTicket?.target}
              >
                <Button variant="orange">
                  {linkBuyTicket?.text}
                </Button>
              </Link>
            ) : null}
            reactNodeHamburger={<Hamburger handleClick={() => setIsOpen(true)} />}
            reactNodeMenu={MenuNode}
          />
        </Container>
      </div>
      <div className="o-header_wrapSub">
        <Container>
          <HeaderSub
            reactNodeHamburger={<Hamburger active handleClick={handleCloseMenu} />}
            reactNodeInput={(
              <FormProvider {...searchMethod}>
                <form
                  onSubmit={searchMethod.handleSubmit(onSubmit)}
                  noValidate
                >
                  <Controller
                    name="search"
                    render={({ field }) => (
                      <Input {...field} id="header-search" placeholder={t('menu.search')} isSearch />
                    )}
                  />
                </form>
              </FormProvider>
            )}
            reactNodeLanguage={(
              <Pulldown
                modifier="triangleGhostWhite"
                value={selectedLang}
                options={languageData}
                handleSelect={handleChangeLang}
              />
            )}
            reactNodeInfo={<Info email={contactInfo.email} phone={contactInfo.phone} />}
            reactNodeMenu={MenuNode}
          />
        </Container>
      </div>
    </header>
  );
};

Hamburger.defaultProps = {
  active: undefined,
  handleClick: undefined,
};

Info.defaultProps = {
  email: '',
  phone: '',
};

export default Header;
